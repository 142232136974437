import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { accountStatuses } from "@hellocontento/contento-common";

import Portlet from "../../components/common/portlet/Portlet";
import { callApi } from "../../utils/ContentoApi";
import SearchResults from "../../components/content/vetted-search/SearchResults";
import SearchFacets from "../../components/content/vetted-search/SearchFacets";
import SearchActiveFilters from "../../components/content/vetted-search/SearchActiveFilters";
import TextFilterBar from "../../components/content/vetted-search/TextFilterBar";
import PaginationV2 from "../../components/common/PaginationV2";
import * as modalActions from "../../state/actions/ModalActions";
import * as PropTypes from "prop-types";
import KeywordBar from "../../components/content/vetted-search/KeywordBar";

KeywordBar.propTypes = { keywords: PropTypes.arrayOf(PropTypes.any) };
export default function SearchVettedContentPage(props) {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [facetFilters, setFacetFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    from: moment()
      .subtract(15, "d")
      .toISOString(),
    to: moment().toISOString()
  });
  const [textFilter, setTextFilter] = useState("");
  const [page, setPage] = useState(1);
  const [searchResult, setSearchResult] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [allAvailableTags, setAllAvailableTags] = useState([]);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    callApi({
      method: "post",
      url: "/contents/search",
      data: {
        limit,
        page,
        facetFilters:
          facetFilters &&
          facetFilters.map(({ field, value }) => ({
            // FIXME: refactor the search code to have the same facet filter structure as the backend
            fields: Array.isArray(field) ? field : [field],
            values: Array.isArray(value) ? field : [value]
          })),
        textFilter,
        dateFilter,
        selectedAccount
      }
    })
      .then(({ result, allTags }) => {
        setAllAvailableTags(allTags);
        setSearchResult(result);
      })
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }, [
    facetFilters,
    textFilter,
    dateFilter,
    page,
    selectedAccount,
    refreshCounter,
    limit
  ]);

  useEffect(() => {
    setKeywords([]);
    if (selectedAccount) {
      callApi({
        method: "get",
        url: `/accounts/${selectedAccount.id}/keywords`
      })
        .then(keywords => setKeywords(keywords))
        .catch(error => {
          console.error(error);
          toastr.error(error.message);
        });
    }
  }, [selectedAccount]);

  useEffect(() => {
    callApi({
      method: "get",
      url: "/accounts",
      params: {
        limit: 1000,
        orderBy: ["companyName", "ASC"],
        all: true,
        filters: {
          status: accountStatuses.ACTIVE
        }
      }
    })
      .then(({ rows }) => setAccounts(rows))
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }, []);

  if (!searchResult) {
    // TODO: spinner
    return null;
  }

  function refreshResults() {
    setRefreshCounter(refreshCounter + 1);
  }

  function onDateFilterChanged(fieldName, date) {
    const newFilter = { ...dateFilter };

    if (date) {
      newFilter[fieldName] = date.toISOString();
    } else {
      delete newFilter[fieldName];
    }

    setDateFilter(newFilter);
  }

  function onFacetFilterRemoved({ field, value }) {
    const newFilters = facetFilters.filter(
      filter => filter.field !== field || filter.value !== value
    );
    setFacetFilters(newFilters);
  }

  function onFacetChange({ field, value, enabled }) {
    if (enabled) {
      setFacetFilters([...facetFilters, { field, value }]);
    } else {
      onFacetFilterRemoved({ field, value });
    }
  }

  function onTextSearchRemoved() {
    setTextFilter("");
  }

  async function onSuggest(content, accountId) {
    try {
      await callApi({
        method: "post",
        url: `/accounts/${accountId}/contents/${content.id}`
      });

      toastr.success("Success!", "The article has been suggested!");
      refreshResults();
    } catch (error) {
      console.error(error);
      toastr.error("Error suggesting content", error.message);
    }
  }

  function updateVerifiedTagsInPlace(record, newTags) {
    // replace without refreshing the search to avoid "disappearing" records
    record.verified_tags = newTags;
    const newResult = { ...searchResult, records: [...searchResult.records] };
    setSearchResult(newResult);
  }

  async function onVerifiedTagAdded(record, tag) {
    if (record.verified_tags && record.verified_tags.find(t => t === tag)) {
      toastr.warning(
        "Warning!",
        `The tag "${tag}" is already applied to this article`
      );
      return;
    }

    try {
      await callApi({
        method: "post",
        url: `/contents/${record.id}/verified-tags`,
        data: {
          tag
        }
      });

      toastr.success(
        "Success!",
        `Added "${tag}" to the list of verified tags!`
      );

      updateVerifiedTagsInPlace(
        record,
        _.uniq([...(record.verified_tags || []), tag].sort())
      );
    } catch (error) {
      console.error(error);
      toastr.error("Error tagging content", error.message);
    }
  }

  async function onVerifiedTagRemoved(record, tag) {
    try {
      await callApi({
        method: "delete",
        url: `/contents/${record.id}/verified-tags`,
        data: {
          tag
        }
      });

      toastr.success(
        "Success!",
        `Removed "${tag}" from the list of verified tags!`
      );

      updateVerifiedTagsInPlace(
        record,
        record.verified_tags.filter(t => t !== tag)
      );
    } catch (error) {
      console.error(error);
      toastr.error("Error removing content tag", error.message);
    }
  }

  function openCheatSheetModal() {
    dispatch(modalActions.openModal("SEARCH_CHEAT_SHEET_MODAL", {}));
  }

  return (
    <div>
      <Portlet
        title={
          <div>
            Content Search{" "}
            <i
              title="Open Search Cheat Sheet"
              onClick={openCheatSheetModal}
              style={{ cursor: "pointer" }}
              className="m-menu__link-icon flaticon-list-1 m--margin-left-10"
            />
          </div>
        }
      >
        <TextFilterBar keywords={keywords} setTextFilter={setTextFilter} />
        <div className="row">
          <div className="col-xl-2">
            <SearchFacets
              searchResult={searchResult}
              accounts={accounts}
              onFacetChange={onFacetChange}
              facetFilters={facetFilters}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              setSelectedAccount={setSelectedAccount}
              onDateFilterChanged={onDateFilterChanged}
            />
          </div>
          <div className="col-xl-10">
            <div className="row">
              <div className="col-xl-9">
                <SearchActiveFilters
                  facetFilters={facetFilters}
                  textFilter={textFilter}
                  dateFilter={dateFilter}
                  onTextSearchRemoved={onTextSearchRemoved}
                  onFacetFilterRemoved={onFacetFilterRemoved}
                  onDateFilterChanged={onDateFilterChanged}
                />
              </div>
              <div className="col-xl-3">
                <PaginationV2
                  page={searchResult.page}
                  totalPages={searchResult.totalPages}
                  totalRecords={searchResult.totalRecords}
                  limit={searchResult.limit}
                  onSelectLimit={setLimit}
                  onSelectPage={setPage}
                />
              </div>
            </div>

            <SearchResults
              records={searchResult.records}
              selectedAccount={selectedAccount}
              onSuggest={onSuggest}
              onVerifiedTagAdded={onVerifiedTagAdded}
              onVerifiedTagRemoved={onVerifiedTagRemoved}
              allAvailableTags={allAvailableTags}
            />
          </div>
        </div>
      </Portlet>
    </div>
  );
}
