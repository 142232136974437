import React from "react";
import { Stages } from "./CreateAccountModal";
import ModalFooter, { IStages } from "../common/ModalFooter";

type CreateAccountModalFooterProps = {
  currentStage: Stages;
  setCurrentStage: (newStage: Stages) => void;
  onSubmit?: () => void;
  actionButtonText: string;
  disableActionButton?: boolean;
  showBackButton?: boolean;
};

const CreateAccountModalFooter = ({
  currentStage,
  setCurrentStage,
  onSubmit,
  actionButtonText,
  disableActionButton,
  showBackButton = true
}: CreateAccountModalFooterProps) => {
  const stageTiles: IStages<Stages>[] = [
    {
      title: "Account Preparation",
      stageNumber: 1,
      active: currentStage === Stages.ACCOUNT_PREPARATION,
      done: currentStage !== Stages.ACCOUNT_PREPARATION,
      value: Stages.ACCOUNT_PREPARATION
    },
    {
      title: "Quote Preparation",
      stageNumber: 2,
      active: currentStage === Stages.QUOTE_PREPARATION,
      done: currentStage === Stages.REVIEW,
      value: Stages.QUOTE_PREPARATION
    }
  ];

  const onBackPressed = () => {
    switch (currentStage) {
      case Stages.ACCOUNT_PREPARATION:
        console.log("Do Nothing ");
        break;
      case Stages.QUOTE_PREPARATION:
        console.log("Setting Stage To:: ", Stages.ACCOUNT_PREPARATION);
        setCurrentStage(Stages.ACCOUNT_PREPARATION);
        break;
      case Stages.REVIEW:
        console.log("Setting Stage To:: ", Stages.QUOTE_PREPARATION);
        setCurrentStage(Stages.QUOTE_PREPARATION);
        break;
    }
  };

  return (
      <ModalFooter
        currentStage={currentStage}
        onSubmit={onSubmit}
        actionButtonText={actionButtonText}
        disableActionButton={disableActionButton}
        showBackButton={showBackButton}
        stageTiles={stageTiles}
        onBackPressed={onBackPressed}
      />
  );
};

export default CreateAccountModalFooter;