import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Image, Flex } from "rebass/styled-components";

import { IStyledHeaderProps } from "./styles.type";

export const StyledHeader = styled.header`
  background: ${(props: IStyledHeaderProps) =>
    props.isTransparent ? "rgb(241, 241, 241)" : "white"};
  box-sizing: border-box;
  box-shadow: ${props =>
    props.isTransparent
      ? "0 1px 0px rgba(0, 0, 0, 0)"
      : "0 1px 2px rgba(0, 0, 0, 0.13)"};
  display: grid;
  grid-template-areas: "brand nav meta";
  padding: 10px 16px;
  position: sticky;
  top: 0;
  transition: 0.25s;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    grid-template-areas: "brand meta" "nav nav";
    grid-template-columns: auto auto;
  }
`;

export const HeaderBrand = styled(Flex)`
  align-items: center;
  grid-area: brand;
`;

export const HeaderNav = styled.div`
  align-self: center;
  grid-area: nav;
  justify-self: center;

  @media only screen and (max-width: ${props =>
      props.theme.stdBreakpoints.sm}) {
    max-width: 375px;
    text-align: center;
    width: 100%;
  }
`;

export const HeaderMeta = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-self: flex-end;
  margin-right: 5px;
`;

export const NavItemList = styled(Flex)`
  @media only screen and (max-width: ${props =>
      props.theme.stdBreakpoints.sm}) {
    margin: 8px 0;
    max-width: calc(100vw - 32px);
    overflow: scroll;
    width: 100%;
  }
`;

export const NavItem = styled(NavLink)`
  margin: 0 8px;
  min-width: 72px;
  padding: 8px;
  border-radius: 20px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text03};
  cursor: pointer;

  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: -0.16px;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.blue};
  }

  & label {
    font-size: 13px;
    line-height: 14px;
  }

  @media only screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    margin: 0 2px;
  }
`;

export const MenuLogo = styled(Image)`
  cursor: pointer;
  height: 40px;
`;

