import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";

import Portlet from "../../components/common/portlet/Portlet";
import SourcesSearchFilters from "../../components/sources/SourcesSearchFilters";
import SourcesSearchResult from "../../components/sources/SourcesSearchResult";
import { callApi } from "../../utils/ContentoApi";

export default function SourceListPage(props) {
  const [refreshFlag, setRefreshFlag] = useState(1);
  const [searchResults, setSearchResults] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [title, setTitle] = useState("");
  const [isAccountSource, setAccountSource] = useState(false);
  const history = useHistory();

  useEffect(() => {
    callApi({
      method: "get",
      url: "/sources",
      params: {
        limit,
        page,
        status,
        type,
        title,
        isAccountSource: isAccountSource
      }
    })
      .then(setSearchResults)
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }, [limit, page, status, type, title, refreshFlag, isAccountSource]);

  if (!searchResults) {
    // TODO: spinner
    return null;
  }

  function onNewSourceClick() {
    history.push("/sources/new");
  }

  function refreshSearch() {
    setRefreshFlag(refreshFlag + 1);
  }

  function onSourceDeleted(source) {
    callApi({
      method: "delete",
      url: `/sources/${source.id}`
    })
      .then(() => {
        toastr.success("Success!", "The source has been deleted!");
        refreshSearch();
      })
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }

  function onSourceReprocessed(source) {
    callApi({
      method: "post",
      url: `/sources/${source.id}`
    })
      .then(() => {
        toastr.success(
          "Success!",
          "The source is being reprocessed! Refresh the page in a few seconds to seet he results."
        );
        refreshSearch();
      })
      .catch(error => {
        console.error(error);
        toastr.error(error.message);
      });
  }

  return (
    <Portlet title="Source Management">
      <div className="row">
        <div className="col-xl-2 text-center">
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginBottom: "5px" }}
            onClick={onNewSourceClick}
          >
            <i className="fa fa-plus">&nbsp;Add Source</i>
          </button>
          <br />
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginBottom: "10px" }}
            onClick={refreshSearch}
          >
            <i className="fa fa-sync">&nbsp;Refresh</i>
          </button>
          <SourcesSearchFilters
            status={status}
            setStatus={setStatus}
            type={type}
            setType={setType}
            setTitle={title => {
              setTitle(title);
              setPage(1);
            }}
            isAccountSource={isAccountSource}
            setAccountSource={setAccountSource}
          />
        </div>
        <div className="col-xl-10">
          <SourcesSearchResult
            searchResults={searchResults}
            setLimit={setLimit}
            setPage={setPage}
            onSourceDeleted={onSourceDeleted}
            onSourceReprocessed={onSourceReprocessed}
          />
        </div>
      </div>
    </Portlet>
  );
}
