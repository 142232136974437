import React from "react";
import { useForm } from "react-hook-form";
import { Flex, Text } from "rebass/styled-components";
import { Link } from "react-router-dom";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";

import { FormStages } from "./CalendarEditorModal";
import { ModalBody } from "../styles";
import CalendarEditorFooter from "./CalendarEditorFooter";
import CalendarCsvInput from "./CalendarCsvInput";
import { CalendarStatus } from "containers/calendars/CalendarStatusFilter";
import { TooltipInfo } from "components/common/styles";

export type CalendarEvent = {
  startsAt: Date;
  endsAt?: Date;
  name: string;
};

export interface CalendarEventsFormInputs {
  csv?: string;
  events: CalendarEvent[];
}

type Props = {
  setCurrentStage: (newStage: FormStages) => void;
  onSubmit: (values: CalendarEventsFormInputs) => void;
  calendarEvents?: CalendarEventsFormInputs;
  calendarStatus?: CalendarStatus;
};

const CalendarEventsForm = ({
  setCurrentStage,
  onSubmit,
  calendarEvents,
  calendarStatus
}: Props) => {
  const { handleSubmit, control } = useForm<CalendarEventsFormInputs>({
    defaultValues: calendarEvents
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Flex
          flexDirection={"column"}
          sx={{
            borderRadius: "5px",
            border: "1px solid #DDDDDD",
            marginX: "40px"
          }}
        >
          <HeadingSection />
          <CalendarCsvInput<CalendarEventsFormInputs>
            control={control}
            name={"events"}
            rules={{
              validate: {
                liveCalendarRequirement: value => {
                  console.log("Live Requirement called:: ", calendarStatus);
                  if (!value) {
                    return `A Calendar of status live must have atleast one event`;
                  }

                  if (Array.isArray(value) && value.length === 0) {
                    return `A Calendar of status live must have atleast one event`;
                  }
                  return undefined;
                }
              }
            }}
          />
        </Flex>
      </ModalBody>
      <CalendarEditorFooter
        currentStage={FormStages.CALENDAR_EVENTS}
        setCurrentStage={setCurrentStage}
        actionButtonText="Save & Continue"
        showBackButton={true}
      />
    </form>
  );
};

const HeadingSection = () => {
  return (
    <Flex
      flexDirection={"column"}
      sx={{
        borderBottom: "1px solid #DDDDDD",
        padding: "17px 17px 29px 17px",
        fontSize: "14px",
        lineHeight: "17.5px"
      }}
    >
      <Flex sx={{ gap: "8px", alignItems: "center" }}>
        <Text fontWeight={600}>Add New Calendar Events</Text>
        <InfoCircle
          size={"12px"}
          cursor={"pointer"}
          data-tip={
            "Dates in the csv should be inputted in the ISO Format 'YYYY-DD-MM'."
          }
        />
        <TooltipInfo effect={"float"} place={"top"} />
      </Flex>
      <Text sx={{ fontWeight: 400, color: "#808082" }}>
        Calendars can only be created by uploading a CSV. You can download the
        template to create a calendar{" "}
        <Link to={"#"}>
          <span style={{ textDecoration: "underline" }}>here</span>
        </Link>
        .
      </Text>
    </Flex>
  );
};

export default CalendarEventsForm;
