import React from "react";

import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import CompanyProfileForm from "../../components/accounts/CompanyProfileForm";
import Portlet from "../../components/common/portlet/Portlet";

const AccountCompanyProfilePage = ({
  updateAccount,
  account,
  deleteAccount
}) => {
  return (
    <Portlet title="Company profile">
      <CompanyProfileForm
        onSubmit={values => updateAccount(account.id, values)}
        handleDelete={() => {
          if (window.confirm("Are you sure you want to delete this account?")) {
            deleteAccount(account.id);
          }
        }}
        initialValues={account}
      /> 
    </Portlet>
  );
};

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount,
  deleteAccount: accountActions.deleteAccount
})(AccountCompanyProfilePage);
