import React from "react";
import styled from "styled-components";

const Icon = styled(({ component, ...props }) =>
  React.createElement(component, props)
)`
  color: ${props => props.color};
  transform: ${props => props.transform};
  stroke-width: ${props => props.strokeWidth ?? "2.5px"};
`;

export default Icon;