import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components";

export const MenuContainer = styled(Box)`
  background: white;
  padding: 8px 6px;
  margin: ${props => (props.margin ? String(props.margin) : "8px 0")};
  overflow: hidden;
  transition: 0.25s height;
  width: ${props => (props.width ? props.width + "px" : "max-content")};

  border-radius: 12px;
  border: 1px solid #66778029;

  /* Shadow M */
  box-shadow: 0px 0px 0px 1px #1c1e1f01, 0px 18px 27px -5px #1c1e1f06,
    0px 8px 16px -8px #1c1e1f04;

  flex-direction: column;
  overflow-y: ${props => (props.maxHeight ? "auto" : "unset")};
`;

interface IMenuEntry extends React.CSSProperties {
  selected?: boolean;
}

export const MenuEntry = styled.div<IMenuEntry>`
  padding: ${props => props.padding ?? "8px 24px"};
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  align-self: stretch;
  justify-content: ${props => props.justifyContent ?? "space-between"};
  letter-spacing: -0.07px;

  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.colors.grey01};
    `}

  &:hover {
    background: ${props => props.theme.colors.primaryNew};
    color: white;
  }

  &:hover .subtitle {
    color: #f3f4f59e;
  }
`;
