import React from "react";
import _ from "lodash";
import { Flex } from "rebass";
import { connect } from "react-redux";
import ScheduleTimeForm from "../../../components/schedule/ScheduleTimeForm";
import { WEEK_DAYS } from "../../../constants";
import { callApi } from "../../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";
import SocialIcon from "../../../components/common/SocialIcon";
import {
  DeleteButton,
  SocialWrapper
} from "../../../components/schedule/styles";

class MarketingPlanSchedulePage extends React.Component {
  state = {
    scheduleTimes: null,
    channels: null
  };

  componentDidMount() {
    this.refreshSchedule();
    this.fetchProfiles();
  }

  refreshSchedule() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/schedule-times`
    })
      .then(scheduleTimes => {
        this.setState({ scheduleTimes: scheduleTimes });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ scheduleTimes: [] });
      });
  }

  fetchProfiles() {
    callApi({
      method: "get",
      url: `/accounts/${this.props.account.id}/channels`
    })
      .then(channels => {
        this.setState({ channels: channels });
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ channels: [] });
      });
  }

  handleAddScheduleTime(accountId, scheduleTimeValues) {
    callApi({
      method: "post",
      url: `/accounts/${this.props.account.id}/schedule-times`,
      data: scheduleTimeValues
    })
      .then(() => {
        //Wait a little bit to prevent refreshing too fast and get incomplete results.
        setTimeout(() => this.refreshSchedule(), 300);
      })
      .catch(error => {
        toastr.error(error.message);
        this.setState({ scheduleTimes: [] });
      });
  }

  handleDeleteScheduleTime(scheduleTimeId) {
    callApi({
      method: "delete",
      url: `/accounts/${this.props.account.id}/schedule-times/${scheduleTimeId}`
    })
      .then(() => {
        toastr.success("Successfully removed schedule time");
        this.refreshSchedule();
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }

  render() {
    const { channels, scheduleTimes } = this.state;
    const { account } = this.props;

    if (!channels || !scheduleTimes) {
      return null;
    }

    const timesByDay = _(scheduleTimes)
      .sortBy(time => time.hour)
      .groupBy(time => time.dayOfWeek)
      .mapValues(scheduleTime => {
        return _(scheduleTime)
          .sortBy("time")
          .groupBy("time")
          .value();
      })
      .value();

    const channelsById = _.keyBy(channels, "id");

    return (
      <div className="m-portlet__body">
        <div className="schedule-container" style={styles.scheduleContainer}>
          {Object.keys(WEEK_DAYS).map(dayOfWeek => {
            return (
              <div
                key={dayOfWeek}
                className="schedule-day"
                style={styles.scheduleDay}
              >
                <div
                  style={{
                    ...styles.scheduleItem,
                    ...styles.scheduleDayHeader
                  }}
                >
                  {WEEK_DAYS[dayOfWeek]}
                </div>

                {timesByDay[dayOfWeek] &&
                  Object.keys(timesByDay[dayOfWeek]).map(time => (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      key={time}
                    >
                      <div style={styles.time}>{time}</div>

                      {timesByDay[dayOfWeek][time].map(({ channelId, id }) => (
                        <SocialWrapper key={id}>
                          <SocialIcon
                            platform={channelsById[
                              channelId
                            ].service.toUpperCase()}
                          />
                          <DeleteButton
                            onClick={() => this.handleDeleteScheduleTime(id)}
                          >
                            x
                          </DeleteButton>
                        </SocialWrapper>
                      ))}
                    </Flex>
                  ))}
              </div>
            );
          })}
        </div>

        {
          <ScheduleTimeForm
            onSubmit={values => this.handleAddScheduleTime(account.id, values)}
            channels={channels}
            initialValues={{
              dayOfWeek: "ALL",
              time: "10:00",
              channels: channels.map(channel => channel.id)
            }}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: state.accounts.selectedAccount
  };
};

export default connect(mapStateToProps, {})(MarketingPlanSchedulePage);

const styles = {
  scheduleContainer: {
    display: "flex",
    flexDirection: "row"
  },
  scheduleDay: {
    display: "flex",
    flexDirection: "column",
    width: "20%"
  },
  scheduleDayHeader: {
    background: "#34bfa3",
    color: "#fff",
    fontWeight: "bold"
  },
  time: {
    fontSize: 16,
    marginRight: 5,
    paddingTop: 2
  }
};
