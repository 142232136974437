const config = {
  development: {
    rootUrl: "http://localhost:3001",
    apiUrl: process.env.REACT_APP_API_URL || "http://localhost:4001",
    frontendUrl: "http://localhost:3000",
    telenetUrl: "http://localhost:3000",
  },
  staging: {
    rootUrl: process.env.PUBLIC_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    telenetUrl: process.env.REACT_APP_TELENET_URL
  },
  production: {
    rootUrl: process.env.PUBLIC_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    telenetUrl: process.env.REACT_APP_TELENET_URL
  }
};

const ENV = process.env.REACT_APP_ENV || "development";

export default config[ENV];
