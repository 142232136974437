import styled from "styled-components";

export const SourceResultsTableRow = styled.tr`
  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

export const Tag = styled.span`
  align-items: center;
  background-color: #34bfa3;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-size: 12px;
  font-weight: 300;
  height: 19.5px;
  justify-content: center;
  line-height: 15.6px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 9.75px 9.75px 9.75px 9.75px;
`;
