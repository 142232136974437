import React, { useEffect, useState } from "react";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import Portlet from "../../components/common/portlet/Portlet";
import { callApi } from "../../utils/ContentoApi";
import { toastr } from "react-redux-toastr";

const defaultStats = {
  hangingPosts: 0,
  emptyChannels: 0,
  channelsWithErrors: 0,
  scheduledPostsByWeek: [],
  postedPostsByWeek: []
};

const OperationsPage = () => {
  const [stats, setStats] = useState(defaultStats);

  useEffect(() => {
    callApi({
      method: "get",
      url: `/scheduler/stats`
    })
      .then(setStats)
      .catch(error => {
        toastr.error(error.message);
        setStats(defaultStats);
      });
  }, []);

  const weeklyPostChartOptions = {
    title: {
      text: "Weekly Posts"
    },
    xAxis: {
      type: "datetime"
    },
    yAxis: {
      title: {
        text: "Posts"
      }
    },
    series: [
      {
        name: "Posted",
        data: stats.postedPostsByWeek.map(d => [
          moment(d.week, "YYYY-WW").valueOf(),
          parseInt(d.count)
        ])
      },
      {
        name: "Scheduled",
        data: stats.scheduledPostsByWeek.map(d => [
          moment(d.week, "YYYY-WW").valueOf(),
          parseInt(d.count)
        ])
      }
    ]
  };

  return (
    <div className="m-content">
      <div className="row">
        <div className="col-xl-4">
          <Portlet>
            <div className="m-widget1">
              <div className="m-widget1__item">
                <div className="row m-row--no-padding align-items-center">
                  <div className="col">
                    <h3 className="m-widget1__title">Hanging Posts</h3>
                    <span className="m-widget1__desc">
                      <Link to="/operations/hanging-posts">More details</Link>
                    </span>
                  </div>
                  <div className="col m--align-right">
                    <span className="m-widget1__number m--font-warning">
                      {stats.hangingPosts}
                    </span>
                  </div>
                </div>
              </div>
              <div className="m-widget1__item">
                <div className="row m-row--no-padding align-items-center">
                  <div className="col">
                    <h3 className="m-widget1__title">Empty Channels</h3>
                  </div>
                  <div className="col m--align-right">
                    <span className="m-widget1__number m--font-warning">
                      {stats.emptyChannels}
                    </span>
                  </div>
                </div>
              </div>
              <div className="m-widget1__item">
                <div className="row m-row--no-padding align-items-center">
                  <div className="col">
                    <h3 className="m-widget1__title">Channels With Issues</h3>
                  </div>
                  <div className="col m--align-right">
                    <span className="m-widget1__number m--font-danger">
                      {stats.channelsWithErrors}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Portlet>
        </div>
        <div className="col-xl-8">
          <Portlet>
            <HighchartsReact
              highcharts={Highcharts}
              options={weeklyPostChartOptions}
            />
          </Portlet>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  fetchAccounts: accountActions.fetchAccounts
})(OperationsPage);
