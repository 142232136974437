import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Progress } from "react-sweet-progress";

import { Image } from "styled-icons/feather/index";
import { callApi } from "../../utils/ContentoApi";

import "react-sweet-progress/lib/style.css";
import { DropZoneContainer, DropZoneTitle } from "./styles";

const DocumentUpload = ({ onDocumentUpload, accountId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = documents => {
    setIsUploading(true);
    const uploads = documents.map(document => {
      const formData = new FormData();
      formData.append("document", document);
      formData.append("timestamp", (Date.now() / 1000) | 0);
      return callApi({
        method: "post",
        url: `accounts/${accountId}/documents/marketing-plan/upload`,
        data: formData,
        headers: [{ "Content-Type": "multipart/form-data" }],
        onUploadProgress: function(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      });
    });

    Promise.all(uploads).then(documentUploadResults => {
      const allDocuments = [].concat.apply([], documentUploadResults);
      setIsUploading(false);
      onDocumentUpload(allDocuments);
    });
  };

  return (
    <Dropzone accept="application/pdf" onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <DropZoneContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <input {...getInputProps()} />

            {!isUploading && (
              <>
                <Image color="#D0D6DA" size={40} />
                <DropZoneTitle>
                  Drop your document here <br /> or click to browse
                </DropZoneTitle>
              </>
            )}
            {isUploading && (
              <>
                <Progress
                  type="circle"
                  strokeWidth={3}
                  width={72}
                  theme={{
                    default: {
                      trailColor: "#D0D6DA",
                      color: "#33ADFF"
                    }
                  }}
                  percent={progress}
                />
              </>
            )}
          </DropZoneContainer>
        );
      }}
    </Dropzone>
  );
};

export default DocumentUpload;
