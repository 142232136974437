import React, { useState, useRef } from "react";
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps
} from "react-hook-form";
import { Flex, Text } from "rebass/styled-components";
import Select, {
  MenuProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  components,
  SelectInstance
} from "react-select";

import { MenuContainer, MenuEntry } from "components/common/Menu";
import {
  StyledInputFieldset,
  StyledInputLabel,
  ErrorMessage
} from "components/common/form/styles";
import { theme } from "theme";
import styled from "styled-components";

enum AvaiablePlans {
  PERSONAL_BRANDING_MONTHLY_US = "willow-personal-branding-us-monthly",
  PERSONAL_BRANDING_ANNUAL_US = "willow-personal-branding-us-yearly",
  SMALL_BIZ_MONTHLY_US = "willow-small-biz-us-monthly",
  SMALL_BIZ_ANNUAL_US = "willow-small-biz-us-yearly",
  PERSONAL_BRANDING_MONTHLY_EUR = "willow_personal_branding_eu_monthly",
  PERSONAL_BRANDING_ANNUAL_EUR = "willow-personal-branding-eu-yearly",
  SMALL_BIZ_MONTHLY_EUR = "willow-small-biz-eu-monthly",
  SMALL_BIZ_ANNUAL_EUR = "willow-small-biz-eu-yearly",
  WILLOW_ELITE_ANNUAL_EUR = "Willow-Elite-EUR-Yearly",
  WILLOW_PRO_ANNUAL_EUR = "Willow-Pro-EUR-Yearly"
}

enum CoachingAddOn {
  MONTHLY_US = "coaching-us-monthly-149",
  ANNUAL_US = "coaching-us-yearly-1788",
  MONTHLY_EUR = "coaching-eu-monthly-149",
  ANNUAL_EUR = "coaching-eu-yearly-1788"
}

export interface IPackage {
  title: string;
  duration: string;
  plan: AvaiablePlans;
  coachingAddOn?: CoachingAddOn;
}

export interface IPackageOption {
  label: string;
  value: IPackage;
}

export const getAccountPackages = (
  currency: "EUR" | "USD"
): IPackageOption[] => [
  ...(currency === "USD"
    ? []
    : [
        {
          label: "Willow Elite",
          value: {
            title: "Willow Elite",
            duration: "Annual Subscription",
            plan: AvaiablePlans.WILLOW_ELITE_ANNUAL_EUR
          }
        },
        {
          label: "Willow Pro",
          value: {
            title: "Willow Pro",
            duration: "Annual Subscription",
            plan: AvaiablePlans.WILLOW_PRO_ANNUAL_EUR
          }
        }
      ]),
  {
    label: "Personal Branding",
    value: {
      title: "Personal Branding",
      duration: "Monthly Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.PERSONAL_BRANDING_MONTHLY_US
          : AvaiablePlans.PERSONAL_BRANDING_MONTHLY_EUR
    }
  },
  {
    label: "Personal Branding & Coaching",
    value: {
      title: "Personal Branding & Coaching",
      duration: "Monthly Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.PERSONAL_BRANDING_MONTHLY_US
          : AvaiablePlans.PERSONAL_BRANDING_MONTHLY_EUR,
      coachingAddOn:
        currency === "USD"
          ? CoachingAddOn.MONTHLY_US
          : CoachingAddOn.MONTHLY_EUR
    }
  },
  {
    label: "Personal Branding",
    value: {
      title: "Personal Branding",
      duration: "Annual Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.PERSONAL_BRANDING_ANNUAL_US
          : AvaiablePlans.PERSONAL_BRANDING_ANNUAL_EUR
    }
  },
  {
    label: "Personal Branding & Coaching",
    value: {
      title: "Personal Branding & Coaching",
      duration: "Annual Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.PERSONAL_BRANDING_ANNUAL_US
          : AvaiablePlans.PERSONAL_BRANDING_ANNUAL_EUR,
      coachingAddOn:
        currency === "USD" ? CoachingAddOn.ANNUAL_US : CoachingAddOn.ANNUAL_EUR
    }
  },
  {
    label: "Small Business",
    value: {
      title: "Small Business",
      duration: "Monthly Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.SMALL_BIZ_MONTHLY_US
          : AvaiablePlans.SMALL_BIZ_MONTHLY_EUR
    }
  },
  {
    label: "Small Business & Coaching",
    value: {
      title: "Small Business & Coaching",
      duration: "Monthly Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.SMALL_BIZ_MONTHLY_US
          : AvaiablePlans.SMALL_BIZ_MONTHLY_EUR,
      coachingAddOn:
        currency === "USD"
          ? CoachingAddOn.MONTHLY_US
          : CoachingAddOn.MONTHLY_EUR
    }
  },
  {
    label: "Small Business",
    value: {
      title: "Small Business",
      duration: "Annual Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.SMALL_BIZ_ANNUAL_US
          : AvaiablePlans.SMALL_BIZ_ANNUAL_EUR
    }
  },
  {
    label: "Small Business & Coaching",
    value: {
      title: "Small Business & Coaching",
      duration: "Annual Subscription",
      plan:
        currency === "USD"
          ? AvaiablePlans.SMALL_BIZ_ANNUAL_US
          : AvaiablePlans.SMALL_BIZ_ANNUAL_EUR,
      coachingAddOn:
        currency === "USD" ? CoachingAddOn.ANNUAL_US : CoachingAddOn.ANNUAL_EUR
    }
  }
];

interface PackageSelectorProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  placeholder: string;
  control?: Control<T>;
  name: Path<T>;
  required?: boolean;
}

const Option = ({ children, ...props }: OptionProps<IPackageOption, false>) => {
  const option = props.data;

  return (
    <MenuEntry
      key={option.value.title}
      selected={props.isSelected}
      onClick={() => {
        props.selectOption(option);
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: "4px"
        }}
      >
        <Text
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "125%"
          }}
        >
          {option.value.title}
        </Text>
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "130%",
            color: "#808082"
          }}
          className="subtitle"
        >
          {option.value.duration}
        </Text>
      </Flex>
    </MenuEntry>
  );
};

const CurrencySelector = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 8px;
  margin-left: 8px;
  width: 50px;
  height: 45px;
  border: 1px solid #6074801f;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #575962;
  transition: border-color 0.2s ease-in-out 0s;
  cursor: pointer;
`;

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<IPackageOption, false>) => {
  const value = props.getValue()[0];
  const displayText = value
    ? `${value.value.title} - ${value.value.duration}`
    : undefined;
  return (
    <components.SingleValue {...props}>
      {value && <Text>{displayText}</Text>}
    </components.SingleValue>
  );
};

const Menu = ({ children, ...props }: MenuProps<IPackageOption, false>) => {
  return (
    <components.Menu {...props}>
      <MenuContainer width={320}>{children}</MenuContainer>;
    </components.Menu>
  );
};

interface ICurrency {
  value: "USD" | "EUR";
  label: string;
}

const currencies: ICurrency[] = [
  {
    value: "EUR",
    label: "€"
  },
  {
    value: "USD",
    label: "$"
  }
];

const PackageSelector = <T extends FieldValues>(
  inputs: PackageSelectorProps<T>
) => {
  const {
    fieldState,
    field: { value, onChange, ...formField }
  } = useController(inputs);
  const selectRef = useRef<SelectInstance<IPackageOption>>(null);
  const [currency, setCurrency] = useState<ICurrency>(currencies[0]);
  const toggleCurrency = () => {
    onChange(undefined);
    selectRef.current?.clearValue();

    setCurrency(currentCurrency => {
      const currentIndex = currencies.findIndex(
        c => currentCurrency.label === c.label
      );

      if (currentIndex === 0) {
        return currencies[1];
      } else {
        return currencies[0];
      }
    });
  };

  const { label, required } = inputs;

  const hasError = fieldState.error !== undefined;

  const styles: StylesConfig<IPackageOption, false> = {
    container: provided => ({
      ...provided,
      marginTop: "8px",
      width: "100%"
    }),
    control: provided => ({
      ...provided,
      borderRadius: "8px",
      borderColor: hasError ? theme.colors.danger : theme.colors.grey02
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "11px 16px"
    }),
    menu: () => ({}),
    input: provided => ({
      ...provided,
      padding: "0",
      margin: "0"
    }),
    placeholder: provided => ({
      ...provided,
      color: theme.colors.text04
    }),
    indicatorSeparator: () => ({}), // removes
    dropdownIndicator: provided => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <StyledInputFieldset>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      <Flex>
        <Select
          placeholder="Select a package and payment terms"
          styles={styles}
          menuPlacement="auto"
          menuPosition="fixed"
          options={getAccountPackages(currency.value)}
          components={{ Menu, SingleValue, Option }}
          {...formField}
          isClearable={true}
          value={value}
          onChange={option => {
            onChange(option);
          }}
          ref={selectRef}
        />
        <CurrencySelector onClick={toggleCurrency}>
          {currency.label}
        </CurrencySelector>
      </Flex>
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default PackageSelector;
