import React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps
} from "react-hook-form";

import { Text } from "rebass/styled-components";

import {
  ErrorMessage,
  HelperText,
  StyledInputWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputFieldset,
  StyledInputIconWrapper,
  SuccessIndicatorWrapper,
  SuccessIndicator
} from "./styles";

interface TextInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label?: string;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  autofocus?: boolean;
  autocomplete?: string;
  showSuccessMark?: boolean;
  defaultValue?: any;
  type?: string;
  required?: boolean;
  maxLength?: number;
}

const TextInput = <T extends FieldValues>(inputs: TextInputProps<T>) => {
  const { field: { value,  ...formField }, fieldState } = useController(inputs);

  const {
    label,
    placeholder,
    type = "text",
    helperText,
    autofocus = false,
    disabled,
    autocomplete,
    marginBottom = 16,
    showSuccessMark = false,
    defaultValue,
    required,
    maxLength,
    ...props
  } = inputs;

  const hasError = fieldState.error !== undefined;

  return (
    <StyledInputFieldset width={1} mb={marginBottom} {...(props as any)}>
      <StyledInputLabel>
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledInputWrapper>
        <StyledInput
          inputSize="sm"
          type={type}
          hasError={hasError}
          placeholder={placeholder}
          autoFocus={autofocus}
          disabled={disabled}
          autoComplete={autocomplete}
          defaultValue={value ?? defaultValue}
          maxLength={maxLength}
          {...formField}
        />
        {maxLength && (
          <StyledInputIconWrapper>
            <Text sx={{ fontSize: "12px" }}>
              {maxLength - ((value as string)?.length ?? 0)}
            </Text>
          </StyledInputIconWrapper>
        )}
        {!hasError && showSuccessMark && fieldState.isTouched ? (
          <StyledInputIconWrapper>
            <SuccessIndicatorWrapper>
              <SuccessIndicator size={8} />
            </SuccessIndicatorWrapper>
          </StyledInputIconWrapper>
        ) : null}
      </StyledInputWrapper>
      {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default TextInput;