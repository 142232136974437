import React, { useState } from "react";
import { useSelector, connect } from "react-redux";

import {
  MenuLogo,
  HeaderNav,
  HeaderMeta,
  HeaderBrand,
  StyledHeader
} from "./styles";
import UserMenu from "./UserMenu";
import { TooltipInfo } from "components/common/styles";
import MainNavigation from "./MainNavigation";
import useWindowSize from "utils/useWindowSize";
import logo from "assets/images/willow-logo-medium.png";
import Button from "components/common/Button";
import { openModal } from "state/actions/ModalActions";
import { Link } from "react-router-dom";
import CreateAccountButton from "./CreateAccountButton";


const  MainHeader = ({ history, openModal }: { history: any, openModal: any }) => {

  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const [isExpanded, setExpanded] = useState(false);
  const windowSize = useWindowSize();

  const handleNavClick = () => {
    if (windowSize.size === "xsmall") setExpanded(false);
  };

  return (
    <StyledHeader>
      <HeaderBrand>
        <MenuLogo src={logo} onClick={() => history.push(`/`)} />
      </HeaderBrand>
      <HeaderNav>
        {(windowSize.size !== "xsmall" || isExpanded) && (
          <MainNavigation wasClicked={handleNavClick} />
        )}
      </HeaderNav>
      <HeaderMeta>
        <Link to="/users/new" className="m-menu__link ">
          <Button
            background="#6074801F"
            textColor="#1C1E1F"
            data-tip={"New User"}
          >
            Create User
          </Button>
        </Link>

        <CreateAccountButton />
        <TooltipInfo effect={"solid"} place={"bottom"} />

        <UserMenu user={currentUser} />
      </HeaderMeta>
    </StyledHeader>
  );
}

// export default MainHeader;

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  openModal: openModal
})(MainHeader);