import Dropzone, { DropEvent } from "react-dropzone";
import { DropZoneContainer, LoadingBox } from "./styles";
import { Plus } from "styled-icons/feather";

import "react-sweet-progress/lib/style.css";
// @ts-ignore
import { Progress } from "react-sweet-progress";

import ReactLoading from "react-loading";
import React, { ReactNode } from "react";

type Props = {
  isUploading: boolean;
  progress: number;
  accept: string;
  onDrop: onDropFuncType;
  type?: string;
  /** View or component to display when no file is selected. Defaults to a plus icon  */
  idleStateView?: ReactNode;
  border?: string;
  width?: string;
  height?: string;
  multiple?: boolean;
};

type onDropFuncType = <T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent) => void;

export default function UploadZone({
  isUploading,
  progress,
  accept = "image/*",
  onDrop,
  type,
  idleStateView,
  border,
  width,
  height,
  multiple = true
}: Props) {
  return (
    <Dropzone accept={accept} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <DropZoneContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            type={type}
            border={border}
            width={width}
            height={height}
          >
            <input {...getInputProps()} multiple={multiple} />

            {/* If a default empty state view is passed, render that, else render the default plus sign */}
            {!isUploading && !!idleStateView && idleStateView}

            {!isUploading && !idleStateView && (
              <>
                <Plus color="#D0D6DA" size={30} />
              </>
            )}

            {isUploading && progress !== 100 && (
              <Progress
                type="circle"
                strokeWidth={3}
                width={72}
                theme={{
                  default: {
                    trailColor: "#D0D6DA",
                    color: "#33ADFF"
                  }
                }}
                percent={progress}
              />
            )}

            {isUploading && progress === 100 && (
              <LoadingBox>
                <ReactLoading color="#999" type="spin" width={20} height={40} />
                <span>Processing, please wait...</span>
              </LoadingBox>
            )}
          </DropZoneContainer>
        );
      }}
    </Dropzone>
  );
}
