import styled from "styled-components";
import { Box, Flex } from "rebass";
// @ts-ignore
import ReactTooltip from "react-tooltip";

export const IconButton = styled(Box)`
  cursor: pointer;
`;

export const Notifications = styled(Box)``;

export const Notification = styled(Flex)`
  padding: 5px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
`;

export const NotificationText = styled(Box)`
  & > em {
    color: black;
    font-style: normal;
  }
`;

export const NotificationTime = styled(Box)`
  color: #999;
`;

// @ts-ignore
export const TooltipInfo = styled(ReactTooltip)`
  background: ${props => props.theme.colors.black};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  color: white;
  line-height: 1;
  font-size: 14px;
  padding: 6px 8px;
  text-align: center;
  min-width: max-content;
`;