import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";

export const LoadingBox = styled(Box)`
  margin-top: 20px;
  font-size: 12px;
  color: #999;
  text-align: center;

  & > div {
    margin: auto;
  }

  & > span {
    display: inline-block;
    margin: 0 5px;
  }
`;

export const DropZoneContainer = styled(Flex)<{ isDragActive: boolean, border?: string }>`
  height: ${props => (props.height ?? ( props.type === "video" ? "170px" : "100px"))};
  width: ${props => (props.width ?? (props.type === "video" ? "300px" : "100px"))};
  min-width: 100px;
  cursor: pointer;
  background-color: #ffffff;
  border: ${props => (props.border ?? `1px dashed ${props.isDragActive ? props.theme.colors.orange : "#b9bbbd"}`)};
  box-sizing: border-box;
  border-radius: 4px;
`;
