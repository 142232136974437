import React from "react";
import { Box } from "rebass/styled-components";
import {
  FieldValues,
  useController,
  UseControllerProps
} from "react-hook-form";

import {
  ErrorMessage,
  HelperText,
  IconExclamationCircle,
  StyledInputLabel,
  StyledTextArea
} from "./styles";

interface TextInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    React.CSSProperties {
  label: string;
  helperText?: string;
  placeholder?: string;
  required?: boolean
}

const TextAreaInput = <T extends FieldValues>(inputs: TextInputProps<T>) => {
  const { field, fieldState } = useController(inputs);

  const { label, helperText, placeholder, required } = inputs;

  const hasError = fieldState.error !== undefined;

  return (
    <Box width={1} mb={24}>
      <StyledInputLabel >
        {`${label} `}{" "}
        {required && <span style={{ color: "#FF0E0E" }}>{` * `}</span>}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledTextArea
        hasError={hasError}
        placeholder={placeholder}
        height={"162px"}
        {...field}
      />
      {fieldState.error && (
        <ErrorMessage>
          <div>
            <IconExclamationCircle />
            {fieldState.error.message}
          </div>
        </ErrorMessage>
      )}
    </Box>
  );
};

export default TextAreaInput;
