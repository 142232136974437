import React from "react";
import _ from "lodash";

import Portlet from "../common/portlet/Portlet";

export default function SourcesSearchFilters(props) {
  function handleTextFilterKeyPress(e) {
    if (e.which === 10 || e.which === 13) {
      props.setTitle(e.target.value);
    }
  }

  function handleCheckboxChange(e, value, list, fn) {
    if (e.target.checked) {
      props[fn](_.uniq([...props[list], value]));
    } else {
      props[fn](props[list].filter(s => s !== value));
    }
  }

  function getCheckBox(value, list, fn) {
    return (
      <div key={value} className="form-group">
        <input
          type="checkbox"
          onChange={e => handleCheckboxChange(e, value, list, fn)}
        />
        &nbsp;
        {_.startCase(value)}
      </div>
    );
  }

  return (
    <div>
      <Portlet title="Title">
        <input
          type="text"
          className="form-control"
          onKeyPress={handleTextFilterKeyPress}
        />
      </Portlet>
      <Portlet title="Account Source">
        <select
          style={{ width: "100%" }}
          onChange={e => props.setAccountSource(e.target.value)}
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
          <option value={""}>Both</option>
        </select>
      </Portlet>
      <Portlet title="Type">
        {["rss", "twitter", "html-links"].map(value =>
          getCheckBox(value, "type", "setType")
        )}
      </Portlet>
      <Portlet title="Status">
        {[
          "active",
          "inactive",
          "error",
          "unknown_feed",
          "invalid_feed",
          "pending-enrichment"
        ].map(value => getCheckBox(value, "status", "setStatus"))}
      </Portlet>
    </div>
  );
}
