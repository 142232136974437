import { useEffect, useRef } from "react";


export default function useOutsideAlerter( ref: React.MutableRefObject<any> | React.MutableRefObject<any>[], cb: () => void) {
  useRef();
  useEffect(() => {
    let _ref = Array.isArray(ref) ? ref : [ref];

    function handleClickOutside(event: any) {
      if (_ref.every(r => r.current && !r.current.contains(event.target))) {
        cb();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref]);
}
