import React from "react";
import _ from "lodash";

import { Tag, SourceResultsTableRow } from "./styles.js";
import PaginationV2 from "../common/PaginationV2";

const tdStyle = { verticalAlign: "middle" };

export default function SourcesSearchResult({
  searchResults,
  setLimit,
  setPage,
  onSourceDeleted,
  onSourceReprocessed
}) {
  if (!searchResults.totalRecords) {
    return <h3>No sources found with the informed filters</h3>;
  }

  function getSourceDetails(source) {
    const common = (
      <div>
        <div>
          <strong>ID: </strong>
          {source.id}
        </div>
        {source.domainId && (
          <div>
            <strong>Domain ID: </strong>
            {source.domainId}
          </div>
        )}
        <div>
          <strong>Is Account Source: </strong>
          {String(source.isAccountSource)}
        </div>
        {source.image && (
          <div>
            <strong>Image: </strong>
            {source.image}
          </div>
        )}
        {source.urlFilter && (
          <div>
            <strong>URL Filter RegExp: </strong>
            {source.urlFilter}
          </div>
        )}
        {source.statusDetail && (
          <div>
            <strong>Status Detials: </strong>
            {source.statusDetail}
          </div>
        )}
      </div>
    );

    if (source.type === "rss") {
      return (
        <div>
          <strong>URL: </strong>
          {source.url}
          <br />
          <strong>Feed: </strong>
          {source.feed}
          <br />
          {common}
        </div>
      );
    }

    if (source.type === "html-links") {
      return (
        <div>
          <strong>URL: </strong>
          {source.url}
          <br />
          {common}
        </div>
      );
    }

    return (
      <div>
        <strong>Handle: </strong>
        {source.handle}
        <br />
        {common}
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PaginationV2
          page={searchResults.page}
          totalPages={searchResults.totalPages}
          totalRecords={searchResults.totalRecords}
          limit={searchResults.limit}
          onSelectLimit={setLimit}
          onSelectPage={setPage}
        />
      </div>
      <table className="table m-table m-table--head-bg-success">
        <thead>
          <tr>
            <th style={{ textAlign: "center", width: "5%" }}>Logo</th>
            <th style={{ textAlign: "center", width: "30%" }}>
              Title {/*<i className="fa fa-angle-down"></i>*/}
            </th>
            <th style={{ textAlign: "center", width: "5%" }}>Type</th>
            <th style={{ textAlign: "center", width: "5%" }}>Status</th>
            <th style={{ textAlign: "center", width: "20%" }}>Topics</th>
            <th style={{ textAlign: "center", width: "5%" }}>Articles</th>
            <th style={{ textAlign: "center", width: "25%" }}>Details</th>
            <th style={{ width: "2,5%" }}></th>
            <th style={{ width: "2,5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {searchResults.sources.map(source => (
            <SourceResultsTableRow key={source.id}>
              <td align="center" style={tdStyle}>
                {source.image && (
                  <img height={64} width={64} src={source.image} />
                )}
              </td>
              <td align="center" style={tdStyle}>
                {source.title}
              </td>
              <td align="center" style={tdStyle}>
                {_.startCase(source.type)}
              </td>
              <td align="center" style={tdStyle}>
                {_.startCase(source.status)}
              </td>
              <td align="center" style={tdStyle}>
                {source.topics &&
                  source.topics.map(topic => <Tag key={topic}>{topic}</Tag>)}
              </td>
              <td align="center" style={tdStyle}>
                {source.articleCount}
              </td>
              <td align="left" style={tdStyle}>
                {getSourceDetails(source)}
              </td>
              <td align="right" style={tdStyle}>
                <button
                  title={"Reprocess Source"}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onSourceReprocessed(source)}
                >
                  <i className="fa fa-screwdriver"></i>
                </button>
              </td>
              <td align="right" style={tdStyle}>
                <button
                  title={"Delete Source"}
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    window.confirm(
                      "Delete this source and its articles that were not suggested, saved or shared?"
                    ) && onSourceDeleted(source)
                  }
                >
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </SourceResultsTableRow>
          ))}
        </tbody>
      </table>
    </div>
  );
}
