import React from "react";
import classNames from "classnames";

const Checkboxes = ({
  input,
  name,
  label,
  meta: { touched, error, warning },
  fullWidth,
  options
}) => {
  const groupClasses = classNames({
    "form-group m-form__group row": true,
    "has-danger": touched && error,
    "has-warning": touched && warning
  });

  const inputWrapperClasses = classNames({
    "col-7": !fullWidth,
    "col-10": fullWidth
  });

  return (
    <div className={groupClasses}>
      <label className="col-2 col-form-label">{label}</label>
      <div className={inputWrapperClasses}>
        <div className="m-checkbox-inline">
          { options.map((option, index) => (
          <label key={index} className="m-checkbox">
            <input type="checkbox"
                   name={`${name}[${index}]`}
                   value={option.value}
                   checked={input.value.indexOf(option.value) !== -1}
                   onChange={event => {
                     const newValue = [...input.value];
                     if(event.target.checked) {
                       newValue.push(option.value);
                     } else {
                       newValue.splice(newValue.indexOf(option.value), 1);
                     }

                     return input.onChange(newValue);
                   }}
            /> {option.label}
            <span />
          </label>
            ))}
        </div>
        {error && touched && (
          <div className="form-control-feedback">{error}</div>
        )}
      </div>
    </div>
  );
};

export default Checkboxes;
