import React, { ReactNode, useState } from "react";
import { callApi } from "../../utils/ContentoApi";
import UploadZone from "./UploadZone";
import { toastr } from "react-redux-toastr";

type Props = {
  onUpload: (images: any) => void;
  onUploadStart?: Function;
  onChange?: Function;
  /** View or component to display when no file is selected. Defaults to a plus icon  */
  idleStateView?: ReactNode;
  width?: string;
  border?: string;
  multiple?: boolean;
};

const ImageUpload = ({
  onUpload,
  onUploadStart,
  onChange,
  idleStateView,
  width,
  border,
  multiple
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = (images: File[]) => {
    setIsUploading(true);
    if (onUploadStart) {
      onUploadStart();
    }

    const uploads = images.map(image => {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("timestamp", ((Date.now() / 1000) | 0).toString());
      return callApi({
        method: "post",
        url: "images/upload",
        data: formData,
        headers: [{ "Content-Type": "multipart/form-data" }],
        onUploadProgress: function(progressEvent: any) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      });
    });

    Promise.all(uploads)
      .then(imageUploadResults => {
        const allImages: any[] = [].concat.apply([], imageUploadResults);
        setIsUploading(false);

        if (onUpload) {
          onUpload(allImages);
        }

        if (onChange) {
          onChange(allImages[0].path);
        }
      })
      .catch(err => {
        console.log(err);
        toastr.error(
          `There was an error uploading the picture. Please try again later or contact support.`,
          ""
        );
        onUpload(false);
      });
  };

  return (
    <UploadZone
      accept="image/*"
      isUploading={isUploading}
      progress={progress}
      onDrop={onDrop}
      idleStateView={idleStateView}
      width={width}
      border={border}
      multiple={multiple}
    />
  );
};

export default ImageUpload;
