import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { toastr } from "react-redux-toastr";

import { closeModal } from "state/actions/ModalActions";
import { modalStyles } from "../styles";
import AccountDetailsForm, {
  CustomerInfoFormInputs
} from "./AccountDetailsForm";
import { callApi } from "utils/ContentoApi";
import QuoteDetailsForm, { QuoteDetailsFormInputs } from "./QuoteDetailsForm";
import ReviewAccountDetails from "./ReviewAccountDetails";
import CreateAccountModalHeader from "./CreateAccountModalHeader";

export interface IAdmin {
  fullName: string;
  id: string;
  email: string;
}

const fetchAdmins = async (inputValue?: any): Promise<IAdmin[]> => {
  const adminResult = await callApi({
    method: "get",
    url: `/users?q=${inputValue ?? ""}&role=admin`
  });

  return adminResult.map(parseUserJson);
};

const parseUserJson = (admin: any): IAdmin => {
  return {
    id: admin.id,
    fullName: admin.fullName,
    email: admin.email
  };
};

type CreateAccountModelProps = {
  onClose: () => void;
  forExistingUser?: boolean;
};

export enum Stages {
  ACCOUNT_PREPARATION = "ACCOUNT_PREPARATION",
  QUOTE_PREPARATION = "QUOTE_PREPARATION",
  REVIEW = "REVIEW"
}

/** 
 * The [forExistingUser] variable is used to signify if we are creating the account for an exising user or a new user
 * When creating for a new user, a user is created simultanously as the account and both are linked.
 * When creating for an existing user, admin is required to signify the user for which the account is being created
*/
const CreateAccountModel = ({
  onClose,
  forExistingUser = false
}: CreateAccountModelProps) => {
  const style = modalStyles("850px", {
    contentStyle: { borderRadius: "20px", padding: "20px 0 10px 0" }
  });

  const [currentStage, setCurrentStage] = useState<Stages>(
    Stages.ACCOUNT_PREPARATION
  );
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoFormInputs>();
  const [quoteDetails, setQuoteDetails] = useState<QuoteDetailsFormInputs>();

  const [isLoadingAdmins, setIsLoadingAdmins] = useState(false);
  const [admins, setAdmins] = useState<IAdmin[]>([]);

  useEffect(() => {
    setIsLoadingAdmins(true);
    fetchAdmins()
      .then(setAdmins)
      .catch(err => toastr.error("API Error", err.message))
      .finally(() => setIsLoadingAdmins(false));
  }, []);

  const onSubmitCustomerInfo = (values: CustomerInfoFormInputs) => {
    setCustomerInfo(values);
    setCurrentStage(Stages.QUOTE_PREPARATION);
  };

  const onSubmitQuoteDetails = (values: QuoteDetailsFormInputs) => {
    setQuoteDetails(values);
    setCurrentStage(Stages.REVIEW);
  };

  return (
    <Modal
      style={style}
      isOpen={true}
      ariaHideApp={false}
      onRequestClose={onClose}
      bodyOpenClassName="overflow-hidden"
      shouldCloseOnOverlayClick={false}
    >
      <CreateAccountModalHeader
        forExistingUser={forExistingUser}
        onClose={onClose}
      />

      {currentStage === Stages.ACCOUNT_PREPARATION && (
        <AccountDetailsForm
          forExistingUser={forExistingUser}
          onSubmit={onSubmitCustomerInfo}
          admins={admins}
          isLoadingAdmins={isLoadingAdmins}
          setCurrentStage={setCurrentStage}
          customerInfo={customerInfo}
        />
      )}

      {currentStage === Stages.QUOTE_PREPARATION && (
        <QuoteDetailsForm
          onSubmit={onSubmitQuoteDetails}
          admins={admins}
          isLoadingAdmins={isLoadingAdmins}
          setCurrentStage={setCurrentStage}
          quoteDetails={quoteDetails}
        />
      )}

      {currentStage === Stages.REVIEW && (
        <ReviewAccountDetails
          forExistingUser={forExistingUser}
          customerDetails={customerInfo!}
          quoteDetails={quoteDetails!}
          setCurrentStage={setCurrentStage}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const Wrapper = connect(mapStateToProps, {
  onClose: closeModal
})(CreateAccountModel);

export default Wrapper;
