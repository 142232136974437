import React from "react";
import { Box, Flex, Text} from "rebass/styled-components";
import { Filter } from "@styled-icons/fluentui-system-filled/Filter";

export enum StatusFilter {
  all = "all",
  live = "live",
  voting = "voting",
  inactive = "inactive"
}

export enum CalendarStatus {
    live = "live",
    voting = "voting",
    inactive = "inactive"
}

type OnChangeFilter = (newFilter: StatusFilter) => void;

const StatusFilterTile = ({
  current: currentFilter,
  value,
  onClick
}: {
  value: StatusFilter;
  current: StatusFilter;
  onClick: OnChangeFilter;
}) => {
  const active = value === currentFilter;
  return (
    <Box
      sx={{
        padding: "7px 14px",
        borderRadius: "24px",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "17.5px",
        backgroundColor: active ? "#1C1E1F" : "#6074801F",
        color: active ? "white" : "#1C1E1F",
        textTransform: "capitalize"
      }}
      onClick={() => onClick(value)}
    >
      {value}
    </Box>
  );
};

const CalendarStatusFilter = ({
  filter,
  onChangeFilter
}: {
  filter: StatusFilter;
  onChangeFilter: OnChangeFilter;
}) => {
  return (
    <Flex sx={{ gap: "23px", alignItems: "center", padding: "0 12px" }}>
      <Flex sx={{ gap: "6px", alignItems: "center" }}>
        <Filter size={"16px"} />
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "15px"
          }}
        >
          Filter by Status
        </Text>
      </Flex>

      <Flex
        sx={{
          gap: "9px"
        }}
      >
        <StatusFilterTile
          value={StatusFilter.all}
          current={filter}
          onClick={onChangeFilter}
        />

        <StatusFilterTile
          value={StatusFilter.live}
          current={filter}
          onClick={onChangeFilter}
        />

        <StatusFilterTile
          value={StatusFilter.voting}
          current={filter}
          onClick={onChangeFilter}
        />

        <StatusFilterTile
          value={StatusFilter.inactive}
          current={filter}
          onClick={onChangeFilter}
        />
      </Flex>
    </Flex>
  );
};

export default CalendarStatusFilter;